import React, {useContext, useRef} from 'react';
import Square from './Square';
import HintBox from './HintBox';
import useGameState from '../hooks/useGameState';
import "./GameBoard.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackward, faForward, faRedo, faShare, faShareNodes, faUndo} from "@fortawesome/free-solid-svg-icons";
import {useSnackbar} from "notistack";
import ThemeSwitcher from "./ThemeSwitcher";

import ThemeContext from "../ThemeContext";
function copyToClipboard(link) {
    navigator.permissions.query({name: "clipboard-write"}).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
            function updateClipboard(link) {
                navigator.clipboard.writeText(link).then(() => {
                    /* clipboard successfully set */
                }, () => {
                    /* clipboard write failed */
                });
            }
            updateClipboard(link)
        } else {
            var range, selection, worked;

            var el = document.createElement('textarea');  // Create a <textarea> element
            el.value = link;                                 // Set its value to the string that you want copied
            el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
            el.style.position = 'absolute';
            el.style.left = '-9999px';                      // Move outside the screen to make it invisible
            document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
            var selected =
                document.getSelection().rangeCount > 0        // Check if there is any content selected previously
                    ? document.getSelection().getRangeAt(0)     // Store selection if found
                    : false;                                    // Mark as false to know no selection existed before
            el.select();                                    // Select the <textarea> content
            document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
            document.body.removeChild(el);                  // Remove the <textarea> element
            if (selected) {                                 // If a selection existed before copying
                document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
                document.getSelection().addRange(selected);   // Restore the original selection
            }
        }
    });


}

const GameBoard = () => {
    const {
        grid,
        hints,
        handleSquareClick: selectSquare,
        isWin,
        handleDoubleClick,
        isSelected,
        moveCount,
        historyBack,
        historyForward,
        historyForwardable,
        originalGrid,
        getLink
    } = useGameState(4);
    const theme = useContext(ThemeContext)
    const {enqueueSnackbar: openShareSnack, closeSnackbar} = useSnackbar()
    const handleSquareClick = (rowIndex, colIndex) => {
        selectSquare(rowIndex, colIndex);
    };

    function getGridComponents(displayFlag = true,targetGrid=grid,displayHint = true) {
        return targetGrid.map((row, rowIndex) => (
            <div key={rowIndex} className="game-row">
                {displayHint&&<HintBox hint={hints.row[rowIndex]}/>}
                {row.map((square, colIndex) => (
                    <Square
                        key={colIndex}
                        color={square.color}
                        flagged={square.flagged && displayFlag}
                        position={{row: rowIndex, col: colIndex}}
                        onClick={() => handleSquareClick(rowIndex, colIndex)}
                        onLongPress={() => handleDoubleClick(rowIndex, colIndex)}
                        selected={isSelected(rowIndex, colIndex)}
                    />
                ))}
            </div>
        ));
    }

    function shareLink() {
        openShareSnack("Link copied to your clipboard, ready to be shared ! ")
        copyToClipboard(getLink())
    }

    return (
        <div className="game-board">
            <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
                <div style={{display: "flex"}}>
                    <ThemeSwitcher/>
                    <button className={"hint-box"} onClick={shareLink}>
                        <FontAwesomeIcon icon={faShareNodes}/></button>
                </div>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div>Move History</div>
                    <button className={"hint-box"} onClick={() => historyBack()}><FontAwesomeIcon icon={faBackward}/>
                    </button>
                    <button disabled={!historyForwardable} className={"hint-box"} onClick={() => historyForward()}>
                        <FontAwesomeIcon icon={faForward}/></button>
                </div>
            </div>
            <div>{getGridComponents()}
                <div className="game-column-hints">
                    <div className="hint-box">
                        {moveCount}
                    </div>
                    {hints.col.map((hint, colIndex) => (
                        <HintBox key={colIndex} hint={hint}/>
                    ))}
                </div>
            </div>

            {isWin() && <div className={"win-screen"}>
                <div className={"win-card"}>
                    <h1>Congratulations</h1>
                    <div>You won in {moveCount} moves</div>
                    <div className={"game-board"}>{getGridComponents(false)}
                        <div className="game-column-hints">
                            <div className="hint-box">
                                {moveCount}
                            </div>
                            {hints.col.map((hint, colIndex) => (
                                <HintBox key={colIndex} hint={hint}/>
                            ))}
                        </div>
                    </div>
                    <div className={"win-actions"}>
                        <button onClick={shareLink}>Share puzzle <FontAwesomeIcon icon={faShareNodes}/></button>
                        <a href="/">Try Another !</a>
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default GameBoard;
