import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import './Square.css';
import ThemeContext from "../ThemeContext";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Square = ({color, position, onClick, onLongPress, selected, flagged}) => {

    const [isPressing, setIsPressing] = useState(false);
    const [animated, setAnimated] = useState(false)
    const pressTimer = useRef(null);
    useEffect(() => {
        if (isPressing) {
            pressTimer.current = setTimeout(() => {
                onLongPress(position);
                setIsPressing(false)
            }, 250); // Trigger onLongPress after 1000ms (1 second)
        } else {
            clearTimeout(pressTimer.current);
        }

        return () => {
            clearTimeout(pressTimer.current);
        };
    }, [isPressing, onLongPress, position]);

    useEffect(() => {
        setAnimated(true);
        const timeout = setTimeout(() => {
            setAnimated(false);
        }, 400);
        return () => clearTimeout(timeout);
    }, [color]);

    const handleMouseDown = () => {
        setIsPressing(true);
    };

    const handleMouseUp = () => {
        if (isPressing && pressTimer.current !== null) {
            onClick(position);
        }
        setIsPressing(false);
    };

    const handleMouseLeave = () => {
        setIsPressing(false);
    };

    const {theme} = useContext(ThemeContext);
    const squareStyle = {
        backgroundColor: theme.squareColors[color],
    };
    if(position.col===0 && position.row===0){
        console.log(color,position,animated)
    }
    return (

        <div
            className={`square ${animated ? 'animated' : ''}`}
            style={squareStyle}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleMouseDown}
            // onTouchEnd={handleMouseUp}
        >
            {flagged && <div className={"flagged"}>
                <FontAwesomeIcon icon={faLock}/>
            </div>}
            {selected && <div className="selected"></div>}


        </div>
    );
};
export default Square
