import React, {useState} from 'react';
import './App.css';
import GameBoard from './components/GameBoard';
import HintBox from "./components/HintBox";
import ThemeContext from "./ThemeContext";
import ThemeSwitcher, {DEFAULT_THEME, getTheme} from "./components/ThemeSwitcher";
import {SnackbarProvider} from "notistack";
import {useCookies} from "react-cookie";

// function HintSpecialCase() {
//     return <div className={"special-case"}>
//         <FontAwesomeIcon icon={faWarning}/> Hint Special Cases <FontAwesomeIcon icon={faChevronDown}/>
//         <div>
//             A hint may show <HintBox
//             hint={[2, 0]}/> for this situation
//             <div>
//                 <h3>Current</h3>
//                 {row(["red","orange","orange","blue"])}
//                 <h3>Real</h3>
//                 {row(["orange","purple","yellow","yellow"])}
//             </div>
//             This is because the orange square do belong in this row,
//         </div>
//     </div>
// }

function App() {
    const [cookie,setCookie,removeCookie] =  useCookies(['hueclue-theme'])
    const [theme, setTheme] = useState(getTheme(cookie["hueclue-theme"]));
    const changeTheme = (newTheme) => {
        setTheme(newTheme);
    };

    return (
        <ThemeContext.Provider value={{theme, changeTheme}}>

                <div className="App" style={{
                    backgroundColor: theme.backgroundColor,
                    color: theme.textColor,
                    "--background-color": theme.backgroundColor,
                    "--shadow-color": theme.shadowColor,
                    "--highlight-color": theme.highlightColor,
                    "--flagged-border-color":theme.flaggedBorderColor,
                    "--flagged-overlay-color":theme.flaggedOverlayColor,
                }}>
                    <SnackbarProvider autoHideDuration={1000} classes={{'containerRoot':'snackbar'}}>

                    <header className="App-header">
                        <h1>HueClue</h1>
                    </header>
                    <main className="App-main">
                        <GameBoard/>
                    </main>
                    <div className={"explainations"}>
                        <h3>Colors on the board have been shuffled</h3>
                        <div>Try to find the original grid from the hints</div>
                        <div style={{display: "flex", alignItems: "center"}}>Each hint <HintBox
                            hint={[2, 1]}/> shows you two numbers
                        </div>
                        <ul style={{fontSize: "1em"}}>
                            <li>The first shows you how much colors in this column/row belong belong
                                in this column/row
                            </li>
                            <li>The second shows you how much colors in this column/row are in the
                                correct position
                            </li>
                        </ul>
                        <div>You can swap any 2 colors squares by taping one then the other</div>
                        <div>Pressing or clicking a color for a long time will freeze it, to allow you to mark colors as right</div>
                        <div>Try to handle the puzzle in as little moves as possible !</div>
                        <div>Your move counter is displayed in the bottom left corner</div>
                        {/*<HintSpecialCase/>*/}
                    </div>
                    </SnackbarProvider>

                </div>
        </ThemeContext.Provider>

    );
}

export default App;
