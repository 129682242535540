import React, {useContext, useState} from 'react';
import ThemeContext from '../ThemeContext';
import "./ThemeSwitcher.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWrench} from "@fortawesome/free-solid-svg-icons";
import {useCookies} from "react-cookie";




const FRANCOIS =     {
    "name": "Aube Etoile",
    "backgroundColor": "#4b4b4b",
    "textColor": "#FFBF9B",
    "squareColors": {
        "red": "#FFF4E0",
        "blue": "#FFBF9B",
        "yellow": "#B46060",
        "green": "#4D4D4D",
        "orange": "#774360",
        "purple": "#CA4E79",
    },
    "highlightColor": "#CDDC39",
    "shadowColor": "#525252",
    "flaggedBorderColor": "#7D9EA8",
    "flaggedOverlayColor": "rgba(255, 191, 155, 0.22)"
}




const THEMES = [
    {
        "name": "Tropical",
        "backgroundColor": "#E0F6E8",
        "textColor": "#274156",
        "squareColors": {
            "red": "#F25F5C",
            "blue": "#247BA0",
            "yellow": "#FFE066",
            "green": "#70C1B3",
            "orange": "#FF8552",
            "purple": "#67407C"
        },
        "highlightColor": "#FFC857",
        "shadowColor": "#9EB7C9",
        "flaggedBorderColor": "#274156",
        "flaggedOverlayColor": "rgba(39, 65, 86, 0.3)"
    },
    {
        "name": "Pastel",
        "backgroundColor": "#FDF1DB",
        "textColor": "#40514E",
        "squareColors": {
            "red": "#F67280",
            "blue": "#A8D0E6",
            "yellow": "#f8ebab",
            "green": "#99B898",
            "orange": "#F8AFA6",
            "purple": "#C06C84"
        },
        "highlightColor": "#F38181",
        "shadowColor": "#B0B4C1",
        "flaggedBorderColor": "#40514E",
        "flaggedOverlayColor": "rgba(64, 81, 78, 0.3)"
    },
    {
        "name": "Vibrant",
        "backgroundColor": "#EDEDED",
        "textColor": "#212121",
        "squareColors": {
            "red": "#FF3C38",
            "blue": "#1982BC",
            "yellow": "#FFD700",
            "green": "#08A045",
            "orange": "#FF9700",
            "purple": "#6A2C70"
        },
        "highlightColor": "#FF9700",
        "shadowColor": "#FF6B6B",
        "flaggedBorderColor": "#212121",
        "flaggedOverlayColor": "rgba(33, 33, 33, 0.3)"
    },
    {
        "name": "Midnight",
        "backgroundColor": "#1C1E26",
        "textColor": "#ECEFF1",
        "squareColors": {
            "red": "#BF616A",
            "blue": "#5E81AC",
            "yellow": "#EBCB8B",
            "green": "#A3BE8C",
            "orange": "#D08770",
            "purple": "#B48EAD"
        },
        "highlightColor": "#CDDC39",
        "shadowColor": "#525252",
        "flaggedBorderColor": "#ECEFF1",
        "flaggedOverlayColor": "rgba(236, 239, 241, 0.3)"
    },
    {
        "name": "Retro",
        "backgroundColor": "#E6E6E6",
        "textColor": "#2D2D2D",
        "squareColors": {
            "red": "#FF5252",
            "blue": "#1E88E5",
            "yellow": "#FFEB3B",
            "green": "#43A047",
            "orange": "#FFA000",
            "purple": "#7C4DFF"
        },            "highlightColor": "#FFD166",

        "shadowColor": "#AFAFAF",
        "flaggedBorderColor": "#2D2D2D",
        "flaggedOverlayColor": "rgba(45, 45, 45, 0.3)"

    },
    FRANCOIS
]
function ThemeSwitcher() {

    const themes = [DEFAULT_THEME,...THEMES]
    const { theme, changeTheme } = useContext(ThemeContext);
    const [open,setOpen] = useState(false)
    const [cookie,setCookie,removeCookie] =  useCookies(['hueclue-theme'])

    const handleThemeChange = (newTheme) => {
        setCookie("hueclue-theme",newTheme.name)
        changeTheme(newTheme);
        setOpen(!open)
    };

    return (
        <React.Fragment>
            <button className={"hint-box"} onClick={()=>setOpen(true)} ><FontAwesomeIcon icon={faWrench}/></button>
            {open && <div className="theme-switcher">
                {themes.map((t) => {
                    const isSelected = t.name === theme.name;
                    const squares = Object.values(t.squareColors).map((c, i) => (
                        <div key={i} className="square" style={{backgroundColor: c}}/>
                    ));
                    const themeStyle = {
                        backgroundColor: t.backgroundColor,
                        color: t.textColor,
                        border: isSelected ? '2px solid var(--flagged-border-color)' : 'none',
                    };
                    return (
                        <button
                            key={t.name}
                            onClick={() => handleThemeChange(t)}
                            style={themeStyle}
                            className="theme-button"
                        >
                            {t.name}
                            <div className="squares-container">{squares}</div>
                        </button>
                    );
                })}
            </div>}
        </React.Fragment>
    );
}





export default ThemeSwitcher;
export const DEFAULT_THEME = {
    name: "Default",
    backgroundColor: '#ffffff',
    textColor: '#000000',
    squareColors: {
        'red': '#F44336',
        'blue': '#2196F3',
        'yellow': '#FFEB3B',
        'green': '#008000',
        'orange': '#FF9800',
        'purple': '#9C27B0'
    },
    "highlightColor": "#FFC107",
    "shadowColor": "#9E9E9E",
    "flaggedBorderColor": "#000000",
    "flaggedOverlayColor": "rgba(0, 0, 0, 0.3)"
};
export function getTheme(str){
    const themes =[DEFAULT_THEME,...THEMES]
    return themes.find((t)=>t.name===str)||DEFAULT_THEME

}
