import {useState, useEffect} from 'react';
import {compress, compressToEncodedURIComponent, decompressFromEncodedURIComponent} from "lz-string";

function ColorSquare(color) {
    return {color: color, flagged: false}
}

const COLORS = ['red', 'blue', 'yellow', 'green', 'orange', 'purple'];
const CHARS_TO_COLORS = {'r': 'red', 'b': 'blue', 'y': 'yellow', 'g': 'green', 'o': 'orange', 'p': 'purple'}
const generateInitialGrid = (size) => {
    const colors = COLORS;
    const grid = [];

    for (let i = 0; i < size; i++) {
        const row = [];
        for (let j = 0; j < size; j++) {
            const randomColor = colors[Math.floor(Math.random() * colors.length)];
            row.push(ColorSquare(randomColor));
        }
        grid.push(row);
    }

    return grid;
};
const shuffleGrid = (grid) => {
    const flattenedGrid = grid.flat();
    for (let i = flattenedGrid.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [flattenedGrid[i], flattenedGrid[j]] = [flattenedGrid[j], flattenedGrid[i]];
    }

    const shuffledGrid = [];
    for (let i = 0; i < grid.length; i++) {
        const row = flattenedGrid.slice(i * grid.length, (i + 1) * grid.length);
        shuffledGrid.push(row);
    }

    return shuffledGrid;
};

function gridToStr(grid) {
    return grid.length + ":" + grid.flat().map(c => c.color[0]).join("")
}

function strToGrid(str) {
    let [length, gridStr] = str.split(":")
    length = parseInt(length)
    const flatGrid = Array.from(gridStr).map(c => CHARS_TO_COLORS[c]).map(ColorSquare)
    return Array(length).fill(null).map((_, i) => flatGrid.slice(i * length, (i + 1) * length))

}

const useGameState = (size) => {
        const [correctGrid, setCorrect] = useState(() => generateInitialGrid(size));
        const [grid, setGrid] = useState(() => shuffleGrid(correctGrid));
        const [selectedSquare, setSelectedSquare] = useState(null);
        const [gridHistory, setGridHistory] = useState([grid]);
        const [historyPosition, setHistoryPosition] = useState(0);
        const [hints, setHints] = useState({
            col: Array(size).fill(null).map(() => [0, 0]),
            row: Array(size).fill(null).map(() => [0, 0])
        });

        const swapSquares = (row1, col1, row2, col2) => {
            const newGrid = [...grid];
            const temp = newGrid[row1][col1];
            newGrid[row1] = [...newGrid[row1]]
            newGrid[row2] = [...newGrid[row2]]
            newGrid[row1][col1] = {...newGrid[row2][col2]};
            newGrid[row2][col2] = {...temp};
            setGrid(newGrid);

            // Update grid history and position after swapping squares
            const newHistory = gridHistory.slice(0, historyPosition + 1);
            newHistory.push(newGrid);
            setGridHistory(newHistory);
            setHistoryPosition(newHistory.length - 1);
        };

        const loadFromUrl = () => {
            if (window.location.pathname === null) {
                return
            }
            const params = window.location.pathname.split("/");
            if (params.length <= 2) {
                return
            }
            const originalStateCompressed = params[params.length - 1]
            const originalState = decompressFromEncodedURIComponent(originalStateCompressed);
            const [gridStr, targetStr] = originalState.split('-')
            if (gridStr && targetStr) {
                const grid = strToGrid(gridStr);
                const targetGrid = strToGrid(targetStr);
                setGrid(grid);
                setCorrect(targetGrid);
                setGridHistory([grid])
            }
        };
        const updateHints = () => {
            const width = correctGrid.length;
            const height = correctGrid.length;
            const columns = Array(width).fill(null).map(() => [0, 0]);
            const rows = Array(height).fill(null).map(() => [0, 0])
            const correct = new Set()
            for (let y = 0; y < correctGrid.length; y++) {
                for (let x = 0; x < correctGrid[y].length; x++) {
                    let color = grid[y][x].color;
                    if (color === correctGrid[y][x].color) {
                        columns[x][1] += 1
                        columns[x][0] += 1
                        rows[y][1] += 1
                        rows[y][0] += 1
                        correct.add(`${x},${y}`)
                    }
                }
            }
            const rowColors = Array(size).fill(null).map(() => [])
            const colColors = Array(size).fill(null).map(() => [])

            for (let y = 0; y < correctGrid.length; y++) {
                for (let x = 0; x < correctGrid[y].length; x++) {
                    if (correct.has(`${x},${y}`)) {
                        continue
                    }
                    let color = correctGrid[y][x].color;
                    rowColors[y].push(color)
                    colColors[x].push(color)
                }
            }
            for (let y = 0; y < size; y++) {
                for (let x = 0; x < size; x++) {
                    if (correct.has(`${x},${y}`)) {
                        continue
                    }
                    let color = grid[y][x].color;
                    let rowColor = rowColors[y];
                    if (rowColor.includes(color)) {
                        rows[y][0] += 1
                        rowColor.splice(rowColor.indexOf(color), 1)
                    }
                    let colColor = colColors[x];
                    if (colColor.includes(color)) {
                        columns[x][0] += 1
                        colColor.splice(colColor.indexOf(color), 1)
                    }
                }
            }


            setHints({col: columns, row: rows})
            // Implement the logic to update hints based on the current grid state.
        };

        const isWin = () => {

            for (let i = 0; i < correctGrid.length; i++) {
                for (let j = 0; j < correctGrid[i].length; j++) {
                    if (grid[i][j].color !== correctGrid[i][j].color) {
                        return false;
                    }
                }
            }
            return true;
            // Implement the logic to check if the current grid state meets the win condition.
        };

        function isInHistory() {
            return historyPosition !== gridHistory.length - 1;
        }

        function focusCurrentGrid() {
            setGrid(gridHistory[gridHistory.length - 1])
            setHistoryPosition(gridHistory.length - 1)
        }

        const handleSquareClick = (row, col) => {
            if (isInHistory()) {
                focusCurrentGrid();
                return
            }
            if (grid[row][col].flagged) {
                return;
            }
            if (selectedSquare && row === selectedSquare[0] && col === selectedSquare[1]) {
                setSelectedSquare(null)
            } else if (selectedSquare) {
                const [selectedRow, selectedCol] = selectedSquare;
                swapSquares(selectedRow, selectedCol, row, col);
                setSelectedSquare(null);
                updateHints();
            } else {

                setSelectedSquare([row, col]);
            }
        };

        const handleFlagToggle = (row, col) => {
            if (isInHistory()) {
                focusCurrentGrid();
                return;
            }
            const newGrid = [...grid]
            newGrid[row] = [...newGrid[row]]
            const square = {...newGrid[row][col]}
            square.flagged = !square.flagged
            newGrid[row][col] = square;
            setGrid(newGrid)
            setGridHistory([...gridHistory.slice(0, gridHistory.length - 1), newGrid])
        }

        const isSelected = (row, col) => {
            return selectedSquare && selectedSquare[0] === row && selectedSquare[1] === col
        }
        const historyBack = () => {
            if (historyPosition > 0) {
                setHistoryPosition(historyPosition - 1);
                setGrid(gridHistory[historyPosition - 1]);
            }
        };

        const historyForward = () => {
            if (historyPosition < gridHistory.length - 1) {
                setHistoryPosition(historyPosition + 1);
                setGrid(gridHistory[historyPosition + 1]);
            }
        };
        const getLink = () => {

            const originalGridStr = gridToStr(gridHistory[0]);
            const correctGridStr = gridToStr(correctGrid);

            const encodedOriginalGrid = compressToEncodedURIComponent(originalGridStr + "-" + correctGridStr);

            return `${window.location.protocol}//${window.location.host}/game/${encodedOriginalGrid}`;
        }


        useEffect(() => {
            updateHints();
        }, [grid]);
        useEffect(() => {
            loadFromUrl();
        }, []);
        return {
            grid,
            hints,
            handleSquareClick,
            isWin,
            handleDoubleClick: handleFlagToggle,
            isSelected,
            moveCount: historyPosition,
            historyBack,
            historyForward,
            originalGrid:gridHistory[0],
            historyForwardable: historyPosition < gridHistory.length - 1,
            getLink,
        };
    }
;

export default useGameState;
