import React from 'react';
import './HintBox.css';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

const HintBox = ({hint}) => {
    const [correctColors, correctPositions] = hint;

    return (
        <div className="hint-box">
            <div className="hint-wrapper">
                <div className="hint-container">
                    <TransitionGroup component={null}>
                        <CSSTransition
                            key={correctColors}
                            timeout={500}
                            classNames="hint"
                        >
                            <span className="hint-text">{correctColors}</span>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
                <span className="separator">:</span>
                <div className="hint-container">
                    <TransitionGroup component={null}>
                        <CSSTransition
                            key={correctPositions}
                            timeout={500}
                            classNames="hint"
                        >
                            <span className="hint-text">{correctPositions}</span>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        </div>
    );
};

export default HintBox;
